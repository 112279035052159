<script setup lang="ts">
import { useNewsletter } from '#imports';

const { isBusy, showDialog, values, onSubmit } = useNewsletter();
</script>

<template>
  <div
    id="newsletter"
    class="w-full bg-gradient-to-r from-primary to-primary-dark h-[230px] flex justify-center items-center"
  >
    <div class="flex flex-col justify-center items-center ml-[230px]">
      <h2 class="text-white text-center text-extra font-bold mb-5">{{ $t('global.joinournewsletter') }}</h2>
      <p class="text-white text-center mb-30">{{ $t('newsletter.headerdesc') }}</p>

      <form
        class="flex gap-x-10 h-[65px] mr-15"
        @submit="onSubmit"
      >
        <ApzInput
          name="email"
          class="min-h-[50px] rounded"
          data-testid="newsletter-email-input"
          help-class="text-white"
          variant="outline"
          wrapper-class="w-[400px]"
          :placeholder="$t('global.enteremail')"
        />
        <ApzButton
          class="h-50 min-w-[125px] rounded px-25 border border-solid border-white bg-transparent"
          data-testid="newsletter-subscribe-button"
          type="primary"
          native-type="submit"
          :disabled="!values.email"
          :loading="isBusy"
        >
          {{ $t('newsletter.subscribe').toUpperCase() }}
        </ApzButton>
      </form>
    </div>

    <NuxtImg
      src="/img/newsletter.png"
      width="228"
      height="177"
      alt="newsletter"
      format="webp"
      loading="lazy"
      class="relative -top-10"
    />
  </div>

  <ApzDialog
    v-model="showDialog"
    :title="$t('newsletter.newsletter')"
    :description="$t('newsletter.success')"
    :actions="[{ text: $t('global.dismiss'), onPress: () => (showDialog = false) }]"
    illustration="submit_success"
  />

  <ClientOnly>
    <ApzToast
      container="#newsletter"
      group="newsletter"
    />
  </ClientOnly>
</template>
